import React, { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text, Button, Icon, Anchor } from "./../../components/elements";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-js-pagination";
import qs from "qs";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import setLog from "../../lib";
import moment from "moment";

function NavBar({ subdomainId }) {
  const [Saving, setSaving] = useState(false);
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [masterDestinations, setMasterDestination] = useState("");
  const [subMasterDestinations, setSubMasterDestination] = useState("");
  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState("");
  const [id, setId] = useState("");
  const [masterExpereances, setMasterExpereance] = useState("");
  const [subMasterExpereances, setSubMasterExpereance] = useState("");
  const [expereance, setExpereance] = useState("");
  const [subExpereance, setSubExpereance] = useState("");
  const [keyword, seKeyword] = useState("");
  const [uploadData, setUploadData] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [optinStatus, setOptinStatus] = useState(0);
  const [altTitle, setAltTitle] = useState("");
  const [cover, setCover] = useState("");
  const [title, setTitle] = useState("");

  const [OptinStatusFilter, setOptinStatusFilter] = useState("");

  useEffect(() => {
    fetchDestination();
    fetchExpereance();
  }, []);

  async function fetchExpereance() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/experiences/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterExpereance(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function fetchDestination() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/destination/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterDestination(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function getSubDestinations(id) {
    setDestination(id);
    if (masterDestinations) var data = masterDestinations.find((e) => e._id == id);
    if (data && data.childs) setSubMasterDestination(data.childs);
  }

  function getSubExpereance(id) {
    setExpereance(id);
    if (masterExpereances) var data = masterExpereances.find((e) => e._id == id);
    if (data && data.childs) setSubMasterExpereance(data.childs);
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      destination: destination,
      subDestination: subDestination,
      expereance: expereance,
      subExpereance: subExpereance,
      keyword: keyword,
      optinStatus: OptinStatusFilter,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/marketplace-products/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function deleteProduct() {
    await axios.post(
      process.env.REACT_APP_API_URL + "app/sub-domain/opt-in",
      qs.stringify({
        id: id,
        optin: 0,
      })
    );

    setShowSwal(false);
    fetchData(1);
    setActivePage(1);
    toast.success("Marketplace optout successful");

    setLog({
      adminUser: userData._id,
      module: "Marketplace",
      action: "Delete",
      note: title,
    });
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }
  async function handleSave() {
    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();
    uploadFormData.append("optinStatus", optinStatus);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("alt_title", altTitle);

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/marketplace-products/update/" + id, uploadFormData, options);
    if (Response.data.status == "success") {
      setOptinStatus("");
      setSaving(false);
      toast.success(Response.data.message);
      setShow(false);
      fetchData(1);

      var noptinStatus = "";
      if (optinStatus == 0) {
        noptinStatus = "Pending";
      }
      if (optinStatus == 1) {
        noptinStatus = "Live";
      }
      if (optinStatus == 2) {
        noptinStatus = "In Force";
      }
      if (optinStatus == 3) {
        noptinStatus = "Expired";
      }

      setLog({
        adminUser: userData._id,
        module: "Marketplace",
        action: "Update",
        note: "Status: " + noptinStatus + ". Product:" + title,
      });
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <div class="form-group">
            <label>Keyword</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
          </div>
        </Col>
        <Col md={6}>
          <div class="form-group">
            <label>Destination</label>
            <select class="form-control" onChange={(e) => getSubDestinations(e.target.value)} value={destination}>
              <option value=""> Select </option>
              {masterDestinations?.length > 0 && masterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Sub Destination</label>
            <select class="form-control" onChange={(e) => setSubDestination(e.target.value)} value={subDestination}>
              <option value=""> Select </option>
              {subMasterDestinations?.length > 0 && subMasterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Experience</label>
            <select class="form-control" onChange={(e) => getSubExpereance(e.target.value)} value={expereance}>
              <option value=""> Select </option>
              {masterExpereances?.length > 0 && masterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Sub Experience</label>
            <select class="form-control" onChange={(e) => setSubExpereance(e.target.value)} value={subExpereance}>
              <option value=""> Select </option>
              {subMasterExpereances?.length > 0 && subMasterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Status</label>
            <select class="form-control" onChange={(e) => setOptinStatusFilter(e.target.value)} value={OptinStatusFilter}>
              <option value=""> Select </option>
              <option value="0"> Pending </option>
              <option value="1"> Live </option>
              <option value="2"> In Force </option>
              <option value="3"> Expired </option>
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <Button
              icon={"search"}
              text={"Search"}
              className="mc-btn primary mt-4 w-100"
              onClick={() => {
                setActivePage(1);
                fetchData(1);
              }}
            />
          </div>
        </Col>
      </Row>
      <br />
      <Col xl={12}>
        <Box className="mc-table-responsive">
          <Table className="mc-table">
            <Thead className="mc-table-head primary">
              <Tr>
                <Th>Title</Th>
                <Th>Location</Th>
                <Th>Subdomain</Th>
                <Th>Exp. Date</Th>
                <Th>Status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody className="mc-table-body even">
              {data?.length > 0 &&
                data.map((item, key) => (
                  <>
                    <Tr key={key}>
                      <Td>
                        <Box className="mc-table-icon">
                          <Icon type="open_in_new" style={{ fontSize: "18px" }} />
                          <Anchor href={"https://" + item.subdomain.retreatURL + ".iamfit4travel.com/product/" + item.slug} target={"_blank"}>
                            {item.title}
                          </Anchor>
                        </Box>
                      </Td>
                      <Td>
                        <strong>{item.parentDestination && item.parentDestination.title}</strong> -
                        {item.subDestination &&
                          item.subDestination.map((item2, key) => {
                            return (
                              <>
                                {item2.title}
                                {key < item.subDestination.length - 1 ? ", " : ""}
                              </>
                            );
                          })}
                      </Td>

                      <Td>
                        <strong>{item.subdomain.retreatURL}.iamfit4travel.com</strong>
                      </Td>
                      <Td>
                        <strong>{moment(item.expDate).format("MMMM Do YYYY")}</strong>
                      </Td>
                      <Td>
                        {item.optinStatus == 0 && <span className="text-warning">Pending</span>}
                        {item.optinStatus == 1 && <span className="text-success">Live</span>}
                        {item.optinStatus == 2 && <span className="text-primary">In Force</span>}
                        {item.optinStatus == 3 && <span className="text-danger">Expired</span>}
                      </Td>
                      <Td>
                        <Box className="mc-table-action">
                          <Button
                            title="Edit"
                            className="material-icons edit"
                            onClick={() => {
                              setOptinStatus(item.optinStatus);
                              setId(item._id);
                              setCover(item.cover);
                              setShow(true);
                              setTitle(item.title);
                            }}
                          >
                            <Icon>edit</Icon>
                          </Button>
                          <Button
                            title="Delete"
                            className="material-icons delete"
                            onClick={() => {
                              setId(item._id);
                              setShowSwal(true);
                              setTitle(item.title);
                            }}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </Box>
                      </Td>
                    </Tr>
                  </>
                ))}
            </Tbody>
          </Table>
        </Box>{" "}
        <Box className="mc-paginate">
          <Text className="mc-paginate-title">
            Total <b>{totalRecod}</b> Results Found
          </Text>
          <nav aria-label="Page navigation example">
            {totalRecod > perPage ? (
              <Pagination
                prevPageText="prev"
                nextPageText="next"
                firstPageText="first"
                lastPageText="last"
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalRecod}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-center"
              />
            ) : (
              ""
            )}
          </nav>
        </Box>
      </Col>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Manage Marketplace</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Status</label>
                <select className="mc-label-field-select w-100 h-sm" value={optinStatus} onChange={(e) => setOptinStatus(e.target.value)}>
                  <option value="0"> Pending </option>
                  <option value="1"> Live </option>
                  <option value="2"> In Force </option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <LabelField label="Alternative Title" type="text" fieldSize="w-100 h-md" value={altTitle} onChange={(e) => setAltTitle(e.target.value)} />
            </Col>
            <Col xl={12}>
              <LabelField label="Image (600x350)" type="file" fieldSize="w-100 h-md" accept="image/*" onChange={(e) => fileHandler(e)} />
            </Col>

            {cover.trim() != "" && (
              <Col xl={12}>
                <img src={process.env.REACT_APP_IMG_URL + "marketplace/cropped/" + cover} className="img-fluid" style={{ height: "150px" }} />
              </Col>
            )}

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleSave()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={deleteProduct} onCancel={() => setShowSwal(false)}>
          This listing will be removed from the marketplace.
        </SweetAlert>
      )}
    </>
  );
}
export default NavBar;
